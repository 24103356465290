.npors-container {
  position: relative;
  padding: 20px;
  font-family: "Noto Sans", sans-serif;
  background-color: #4a90e2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.npors-hero {
  text-align: center;
  padding: 40px 20px;
  background: transparent;
  color: white;
  position: relative;
}

.npors-hero h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.npors-hero p {
  font-size: 1.2em;
}

.npors-image-section {
  text-align: center;
  margin: 20px 0;
}

.npors-image-section img {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.npors-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  background: #e3e6f1;
  border-radius: 10px;
}

.npors-info > div {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: black;
}

.assessment-info {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  color: black;
}

.npors-info h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.npors-info ul {
  list-style-type: none;
  padding: 0;
}

.npors-info li {
  padding: 5px 0;
  font-size: 1.1em;
  color: black;
}

.npors-requirements {
  display: flex;
  flex-direction: column;
}

.npors-requirements > div {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: black;
}

.npors-requirements h2,
.npors-requirements h3 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.npors-requirements p {
  font-size: 1em;
  line-height: 1.6;
  color: black;
}

.assessment-heading {
  margin-top: 30px;
  margin-bottom: 20px;
  color: black;
}

.npors-categories {
  background: #e3e6f1;
  margin-top: 20px;
  border-radius: 10px;
  color: #2c3e50;
}

.npors-categories h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.category {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
  min-width: 280px;
  color: #2c3e50;
}

.category h3 {
  font-size: 1.3em !important;
  margin-bottom: 10px !important;
  color: #4a90e2 !important;
}

.category ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #666;
}

.category li {
  padding: 5px 0;
  font-size: 1.1em;
  color: #666;
}

.button-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two buttons per row */
  gap: 20px; /* Space between buttons */
}

.button-list button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a90e2;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  height: 170px;
  text-align: left;
}

.button-list button:hover {
  background: #367bb7;
  transform: scale(1.05);
}

.button-list button:active {
  background: #2c5e8f;
}

.button-content {
  display: flex;
  align-items: center;
  flex: 1;
}

.button-content .button-text {
  flex: 2;
  font-size: 1.1rem;
}

.button-content .button-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.button-content .button-image img {
  height: 150px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .button-list {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

@media (min-width: 1600px) {
  .npors-content {
    max-width: 1000px;
  }
}
