.machine-container {
  padding: 20px;
  font-family: "Noto Sans", sans-serif;
  background-color: #4a90e2;
}

.machine-hero {
  text-align: center;
  padding: 40px 20px;
  background: transparent;
  color: #f9f9f9;
}

.machine-content {
  margin: 0 auto;
  position: relative;
}

.machine-hero h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.course-objective,
.learning-outcomes,
.assessment,
.entry-requirement,
.certification,
.course-summary {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #2c3e50;
  margin-bottom: 20px;
}

.course-objective h2,
.learning-outcomes h2,
.assessment h2,
.entry-requirement h2,
.certification h2,
.course-summary h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.learning-outcomes ul {
  padding: 0;
}

.learning-outcomes li {
  padding: 3px 0;
  list-style-type: disc;
  margin-left: 20px;
}

.contact-us-section {
  text-align: center;
  margin-top: 20px;
}

.contact-us-button {
  background-color: white;
  color: black;
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}

.contact-us-button:hover {
  background-color: #f0f0f0;
}

.no-bullets {
  list-style-type: none;
  padding-left: 0;
}

@media (min-width: 1600px) {
  .machine-content {
    max-width: 1000px;
  }
}
