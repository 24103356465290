.footer {
  background-color: #333;
  color: #f2f2f2;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Aligns items vertically at the center */
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.footer-column {
  flex: 1;
  margin: 0 10px;
  text-align: center; /* Center the text inside each column */
}

.footer-column h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ffd700;
}

.footer-column p,
.footer-column a {
  margin: 5px 0;
  color: #f2f2f2;
  text-decoration: none;
  display: block;
}

.footer-column a:hover {
  color: #ffd700;
}

.footer p {
  margin: 10px 0 0;
}

@media (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin: 10px 0;
    text-align: center; /* Ensures text is centered on mobile as well */
  }

  .footer {
    font-size: 12px;
  }
}
