.contact-container {
  position: relative;
  padding: 20px;
  font-family: "Noto Sans", sans-serif;
  color: #2c3e50;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4a90e2;
  z-index: -1;
}

.contact-content {
  width: 100%;
}

.contact-hero {
  text-align: center;
  padding: 40px 20px;
  background: transparent;
  color: #f9f9f9;
  position: relative;
}

.contact-hero h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.contact-form-section {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  text-align: center;
  width: 100%;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  background: #4a90e2;
  color: #fff;
  padding: 10px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 80%;
  max-width: 300px;
  height: 40px;
  transition: background 0.3s ease;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.submit-button:hover {
  background: #357abf;
}

.contact-info-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-information {
  text-align: center;
}

.contact-information ul {
  list-style: none;
  padding: 0;
}

.contact-information li {
  margin-bottom: 10px;
  color: #2c3e50;
}

.contact-heading {
  margin-bottom: 20px;
}

.social-media-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: -25px;
  margin-bottom: 5px;
}

.social-media-links a {
  font-size: 3.5rem;
  color: #4a90e2;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-media-links a:hover {
  color: #357abf;
  transform: scale(1.1);
}

.location {
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  background: white;
}

.contact-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #2c3e50;
}

.map-container {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1600px) {
  .contact-content {
    max-width: 1000px;
  }
}

@media (max-width: 768px) {
  .submit-button {
    width: 90%;
    height: 35px;
    font-size: 0.9rem;
    padding: 8px 20px;
  }
}

@media (max-width: 480px) {
  .submit-button {
    width: 100%;
    height: 30px;
    font-size: 0.8rem;
    padding: 6px 15px;
  }
}
