* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

textarea {
  font-family: "Noto Sans", sans-serif;
  height: 250px !important;
  resize: none !important;
}

.App {
  text-align: center;
}

li {
  list-style: none;
}

.hero {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/constructionhero.gif);
  background-size: cover;
  background-position: center;
  filter: blur(4px);
  z-index: -1;
}

.hero h1 {
  font-size: 3.5em;
  margin-bottom: 0.3em;
}

.hero p {
  font-size: 1.5em;
  max-width: 600px;
  color: #f9f9f9;
}

.intro-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  min-height: 100vh;
  padding: 20px;
}

.intro-content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  gap: 100px;
  padding: 20px;
  width: 100%;
}

.intro-image {
  width: 40%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.intro-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.intro-text h2 {
  font-size: 2.5em;
  color: #2c3e50;
  margin-bottom: 10px;
}

.intro-text p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666;
}

.cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #e3e6f1;
}

.cards-container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
}

.card {
  width: 45%;
  min-width: 400px;
  max-width: 600px;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f0f4f8);
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, width 0.3s ease;
  display: flex;
  gap: 20px;
  align-items: center;
  color: #2c3e50;
  height: 300px;
}

.card img {
  width: 40%;
  height: auto;
  border-radius: 8px;
}

.card:hover {
  transform: translateY(-10px);
}

.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card h2 {
  font-size: 1.7em;
  margin-bottom: 0.5em;
  color: #4a90e2;
}

.card p {
  font-size: 1em;
  color: #666;
}

.card-content button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #f5a623;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.card-content button:hover {
  background-color: #f59f23;
}

.cpcs-social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpcs-social-icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  justify-content: center;
}

.cpcs-social-icons a {
  font-size: 3.5rem !important;
  color: #4a90e2 !important;
  transition: color 0.3s ease, transform 0.3s ease !important;
}

.cpcs-social-icons a:hover {
  color: #357abf !important;
  transform: scale(1.1);
}

.contact-cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #4a90e2 !important;
}

.contact-cards-container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

.contact-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
}

.contact-card {
  width: 45%;
  min-width: 400px;
  max-width: 600px;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f0f4f8);
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, width 0.3s ease;
  display: flex;
  gap: 20px;
  align-items: center;
  color: #2c3e50;
  height: 600px;
}

.contact-card-banner {
  width: 90%;
  min-width: 400px;
  max-width: 900px;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f0f4f8);
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, width 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c3e50;
  gap: 20px;
  height: auto;
}

.contact-card-banner img {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.contact-card-img {
  width: 40%;
  margin-top: -20px;
  border-radius: 8px;
}

.contact-card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.contact-card h2 {
  font-size: 1.7em;
  margin-bottom: 0.5em;
  color: #4a90e2 !important;
}

.contact-card p {
  font-size: 1em;
  color: black !important;
}

.contact-card form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-card form input,
.contact-card form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.contact-card form textarea {
  resize: vertical;
  height: 100px;
}

.contact-card form button {
  padding: 10px 15px;
  background-color: #f5a623;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.contact-card form button:hover {
  background-color: #f59f23;
}

.contact-card .cpcs-contact-details p {
  color: #f0f4f8;
  margin: 5px 0;
  font-size: 1em;
}

.contact-card .cpcs-social-media h2 {
  font-size: 1.4em;
  color: #ffffff;
  margin-top: 20px;
}

.contact-card .cpcs-social-media p {
  color: #f0f4f8;
  font-size: 0.9em;
}

.contact-card .cpcs-social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.contact-card .cpcs-social-icons a {
  color: #ffffff;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.contact-card .cpcs-social-icons a:hover {
  color: #f5a623;
}

.feedback-message {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .intro-content {
    flex-direction: column-reverse;
    text-align: center;
  }

  .intro-image {
    width: 100%;
  }
  .cards-container,
  .contact-cards-container {
    padding: 10px;
  }

  .card,
  .contact-card {
    width: 90%;
    min-width: auto;
    margin: 0 auto;
  }

  .contact-card-banner {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 375px) {
  .cards-container,
  .contact-cards-container {
    padding: 5px;
  }

  .card,
  .contact-card {
    width: 90%;
    min-width: auto;
    margin: 0 auto;
  }

  .contact-card-banner {
    width: 90%;
    margin: 0 auto;
    min-width: auto;
  }
}
