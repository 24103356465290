html {
  scroll-behavior: smooth;
}

.navbar {
  top: 0;
  width: 100%;
  background: #000;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 100px;
}

.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  height: 100px;
  width: auto;
}

.nav-links {
  display: flex;
  gap: 20px;
  transition: transform 0.3s ease;
  margin-left: auto; /* Push nav-links to the right */
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: width 0.3s ease;
}

.nav-links a:hover::before {
  width: 100%;
}

.nav-links a:hover {
  color: #f5f5f5;
  background-color: rgba(255, 255, 255, 0.1);
}

.burger-icon {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
  margin-left: auto; /* Push burger icon to the right */
}

@media (max-width: 992px) {
  .nav-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    justify-content: flex-start; /* Align links to the top */
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    gap: 20px;
    padding-top: 20px; /* Optional: Add padding to the top for spacing */
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .burger-icon {
    display: block;
    color: white;
  }
}
