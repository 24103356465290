.nvq-container {
  position: relative;
  padding: 20px;
  font-family: "Noto Sans", sans-serif;
  color: black;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nvq-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4a90e2;
  z-index: -1;
}

.nvq-hero {
  text-align: center;
  padding: 40px 20px;
  background: transparent;
  color: #f9f9f9;
  position: relative;
}

.nvq-hero h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.nvq-hero p {
  font-size: 1.2em;
}

.nvq-image-section {
  text-align: center;
  margin: 20px 0;
}

.nvq-image-section img {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.nvq-info > div {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: black;
}

.nvq-info h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.nvq-info ul {
  list-style-type: none;
  padding: 0;
}

.nvq-info li {
  padding: 5px 0;
  font-size: 1.1em;
  color: black;
}

.nvq-requirements {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.nvq-requirements > div {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: black;
}

.nvq-requirements h2,
.nvq-requirements h3 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.nvq-requirements p {
  font-size: 1em;
  line-height: 1.6;
  color: black;
}

.nvq-assessment-heading {
  margin-top: 30px;
  margin-bottom: 20px;
  color: black;
}

.nvq-categories h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.nvq-categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.nvq-category {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
  min-width: 280px;
  color: black;
}

.nvq-category h3 {
  font-size: 1.3em !important;
  margin-bottom: 10px !important;
  color: #4a90e2 !important;
}

.nvq-category ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: black;
}

.nvq-category li {
  padding: 5px 0;
  font-size: 1.1em;
  color: black;
}

@media (min-width: 1600px) {
  .nvq-content {
    max-width: 1000px;
  }
}
